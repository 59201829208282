<template>
    <v-container fluid>
        <employee-details :employee="employee"></employee-details>
    </v-container>
</template>

<script>
import employeeDetails from '../../components/EmployeeDetailsComponent';
export default {
    components: {
        'employee-details': employeeDetails,
    },

    data: () => {
        return {
            id: null,
            employee: {},
        }
    },

    async created() {
        this.id = this.$route.params.id;
        let employees = await this.$store.dispatch('employee/getEmployees', {
            apiParams: {
                id: this.id,
                with_relations: true,
            },
            options: {
                returnData: true
            }
        })

        this.employee = employees[0];
    }
}
</script>